import React from 'react';

import JotformEmbed from 'react-jotform-embed';

interface InscriptionFormProps {
  formURL: string;
  className?: string
}

const InscriptionForm: React.FC<InscriptionFormProps> = ({formURL, className}) => (
  <section className={`py-layout-3 ${className}`}>
    <div className="container">
      <JotformEmbed src={formURL} className="inscription-form-embed" />
    </div>
  </section>
);

export default InscriptionForm;
