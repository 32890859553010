import React from 'react';

import InscriptionForm from '../InscriptionForm/InscriptionForm';

const InscriptionSalvador: React.FC = () => {
  return (
    <section className="py-layout-5 bg-light-gray-1-5">
      <div className="container">
        <InscriptionForm formURL="https://form.jotform.com/222266428971664"/>
      </div>
    </section>
  )
}

export default InscriptionSalvador;
