import React from 'react';

import './styles.scss';

const InscriptionHero: React.FC = ({children}) => (
  <section className="inscription-hero">
  <div className="container">
    <div className="row text-center">
      <div className="col-12">
        {children}
      </div>
    </div>
  </div>
</section>
)

export default InscriptionHero;
